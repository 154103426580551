import React, { useEffect } from 'react';
import ClientLayout from '../../layouts/ClientLayout';
import { useContext, useState } from 'react';
import classes from './legal.module.css';

interface LegalProps {
  legalModalFunc: (open: boolean) => void;
}

const Legal: React.FC<LegalProps> = ({ legalModalFunc }) => {
  return (
    <ClientLayout>
      <div className={classes.outsideLegalContainer}>
        <div className={classes.legalContainer}>
          <div className={classes.legalHeaderContainer}>
            <div className={classes.legalHeader}>
              <div className={classes.legalHeaderText}>LEGAL</div>
            </div>

            <div className={classes.legalHeaderButtonContainer}>
              <div className={classes.legalHeaderButton}>
                <div
                  className={classes.legalHeaderButtonText}
                  onClick={() => {
                    legalModalFunc(false);
                  }}
                >
                  Close
                </div>
              </div>
            </div>
          </div>

          <div className={classes.legalBody}>
            <div className={classes.subtitle}>ListGen Privacy Policy</div>
            <div className={classes.subtext}>
              Protecting your private information is our priority. ListGen Inc. is a third party vendor contracted by
              real estate agents that subscribe to our web hosting services. This Statement of Privacy applies to
              ListGen Inc. and governs data collection and usage. The website that ListGen hosts (ourproperty.info) on
              behalf of select licensed real estate agents, provides a means by which homeowners can request a
              complimentary and confidential home valuation from the real estate agent. By using the ListGen hosted
              website, the user consents to the data practices described in this statement.
            </div>
            <div className={classes.subtitle}> Collection of your Personal Information</div>
            <div className={classes.subtext}>
              In order to better provide you with products and services offered by the real estate agent, ListGen may
              collect personally identifiable information, such as your:
            </div>
            <div className={classes.subtext}> – First and Last Name</div>
            <div className={classes.subtext}> – Property Address</div>
            <div className={classes.subtext}> – Email Address</div>
            <div className={classes.subtext}> – Phone Number</div>
            <div className={classes.subtext}>
              Other than the above four items we do not collect any personal information about you unless you
              voluntarily provide it to us. However, you may be required to provide certain personal information to us
              when you elect to use certain products or services such as registering for an account or asking for an
              email or phone call response. To wit, we will use your information for, but not limited to, communicating
              with you in relation to services and/or products you have requested from the real estate agent.
            </div>
            <div className={classes.subtitle}>Displaying your property’s public information</div>
            <div className={classes.subtext}>
              The website that is being served to the homeowner may contain several property details such as:
            </div>
            <div className={classes.subtext}> – Number of Bedrooms</div>
            <div className={classes.subtext}> – Number of Bathrooms</div>
            <div className={classes.subtext}> – Living Square Feet</div>
            <div className={classes.subtext}> – Lot Size</div>
            <div className={classes.subtext}>
              The above public property details are sourced from either the local Multiple Listing Service (MLS) or
              public county records. The intent of displaying this information to the homeowner is to assist the agent
              in understanding the basic property details prior to providing a home valuation. The homeowner may edit
              the above four property details which are then shared with the agent. Any comments the homeowner leaves on
              the website are held in confidence with the agent and are never shared with any other source or third
              party.
            </div>

            <div className={classes.subtitle}>Use of your Personal Information</div>
            <div className={classes.subtext}>
              ListGen collects and uses your personal information to operate and deliver the services you have
              requested. ListGen may also use your personally identifiable information to inform you of other products
              or services available from the real estate agent that you have contacted for a complimentary and
              confidential home valuation.
            </div>
            <div className={classes.subtitle}> Sharing Information with Third Parties</div>
            <div className={classes.subtext}>
              ListGen does not, under any conditions, sell, rent or lease its customer lists (your information) to third
              parties. ListGen may share data with trusted partners to help perform statistical analysis, send you email
              or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited
              from using your personal information except to provide these services to ListGen, and they are required to
              maintain the confidentiality of your information.
            </div>
            <div className={classes.subtext}>
              ListGen may disclose your personal information, without notice, if required to do so by law or in - the
              good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with
              legal process served on ListGen or the site; (b) protect and defend the rights or property of ListGen;
              and/or (c) act under exigent circumstances to protect the personal safety of users of ListGen, or the
              public.
            </div>
            <div className={classes.subtitle}> Tracking User Behavior</div>
            <div className={classes.subtext}>
              ListGen may keep track of the websites and pages our users visit, in order to determine what website
              services are the most popular. This data is used to deliver customized content and advertising within the
              ListGen hosted website to customers whose behavior indicates that they are interested in a particular
              subject area.
            </div>
            <div className={classes.subtitle}>Automatically Collected Information </div>
            <div className={classes.subtext}>
              Information about your computer hardware and software may be automatically collected by ListGen. This
              information can include: your IP address, browser type, domain names, access times and referring website
              addresses. This information is used for the operation of the service, to maintain quality of the service,
              and to provide general statistics regarding use of the ListGen hosted website.{' '}
            </div>
            <div className={classes.subtitle}> Security of your Personal Information</div>
            <div className={classes.subtext}>
              ListGen secures your personal information from unauthorized access, use, or disclosure. ListGen uses the
              following methods for this purpose:
            </div>
            <div className={classes.subtext}>– SSL Protocol</div>
            <div className={classes.subtext}>
              If I or when personal information such as homeowner name(s), email address(s) and phone number(s) are
              transmitted to other websites, they are protected through the use of encryption, such as the Secure
              Sockets Layer (SSL) protocol.
            </div>
            <div className={classes.subtext}>
              We strive to take appropriate security measures to protect against unauthorized access to or alteration of
              your personal information. Unfortunately, no data transmission over the Internet or any wireless network
              can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information,
              you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are
              beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged
              between you and us through this Site cannot be guaranteed.
            </div>
            <div className={classes.subtitle}> Right to Deletion</div>
            <div className={classes.subtext}>
              Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
            </div>
            <div className={classes.subtext}> • Delete your personal information from our records; and</div>
            <div className={classes.subtext}>
              • Direct any service providers to delete your personal information from their records.
            </div>
            <div className={classes.subtext}>
              Please note that we may not be able to comply with requests to delete your personal information if it is
              necessary to:
            </div>
            <div className={classes.subtext}>
              • Complete the transaction for which the personal information was collected, fulfill the terms of a
              written warranty or product recall conducted in accordance with federal law, provide a good or service
              requested by you, or reasonably anticipated within the context of our ongoing business relationship with
              you, or otherwise perform a contract between you and us;
            </div>
            <div className={classes.subtext}>
              • Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or
              prosecute those responsible for that activity;
            </div>
            <div className={classes.subtext}>
              • Debug to identify and repair errors that impair existing intended functionality;
            </div>
            <div className={classes.subtext}>
              • Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech,
              or exercise another right provided for by law;
            </div>
            <div className={classes.subtext}> • Comply with the California Electronic Communications Privacy Act;</div>
            <div className={classes.subtext}>
              • Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest
              that adheres to all other applicable ethics and privacy laws, when our deletion of the information is
              likely to render impossible or seriously impair the achievement of such research, provided we have
              obtained your informed consent;
            </div>
            <div className={classes.subtext}>
              • Enable solely internal uses that are reasonably aligned with your expectations based on your
              relationship with us;
            </div>
            <div className={classes.subtext}> • Comply with an existing legal obligation; or</div>
            <div className={classes.subtext}>
              • Otherwise use your personal information, internally, in a lawful manner that is compatible with the
              context in which you provided the information. This is a RocketLawyer.com document.
            </div>
            <div className={classes.subtitle}> Children Under Thirteen</div>
            <div className={classes.subtext}>
              ListGen does not knowingly collect personally identifiable information from children under the age of
              thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use
              this website.
            </div>

            <div className={classes.subtitle}> External Data Storage Sites</div>
            <div className={classes.subtext}>
              We may store your data on servers provided by third party hosting vendors with whom we have contracted.
            </div>
            <div className={classes.subtitle}> Changes to this Statement</div>
            <div className={classes.subtext}>
              ListGen reserves the right to change this Privacy Policy from time to time. We will notify you about
              significant changes in the way we treat personal information by sending a notice to the primary email
              address specified in your account, by placing a prominent notice on our website, and/or by updating any
              privacy information. Your continued use of the website and/or Services available after such modifications
              will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be
              bound by that Policy.
            </div>
            <div className={classes.subtitle}> Contact Information</div>
            <div className={classes.subtext}>
              ListGen welcomes your questions or comments regarding this Statement of Privacy.
            </div>

            <div className={classes.subtext}>
              If you believe that ListGen has not adhered to this Statement, please contact ListGen at
              support@listgen.com Please reference: ListGen Privacy Policy
            </div>

            <div className={classes.subtext}>Effective as of October 5th, 2023 </div>

            <div className={classes.legalHeaderLowerPageButtonContainer}>
              <div className={classes.legalHeaderButton}>
                <div
                  className={classes.legalHeaderButtonText}
                  onClick={() => {
                    legalModalFunc(false);
                  }}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ClientLayout>
  );
};

export default Legal;
