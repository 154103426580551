import classes from '../page.module.css';
import PageTracker from './PropInfoCommon/PageTracker';

import CoverPageInfo from './PropInfoCommon/CoverPageInfo';
import { HOData, HomeownerEditFormData, FormDataErrors, OldFormData } from '../../types/hoDataTypes';
import classnames from 'classnames';
import { lc, IOrWe, MeOrUs } from '../../util/propertyUtil.jsx';

type SecondPageType = {
  mailingDetails: any;
  changeDetails: any;
};
interface SecondPageProps {
  homeownerData: HOData;
  changeStepFunc: (step: number) => void;
  handleInputChangeFunc: (value: React.ChangeEvent<HTMLInputElement>) => void;
  handleTextareaChangeFunc: (value: React.ChangeEvent<HTMLTextAreaElement>) => void;
  editFormData: HomeownerEditFormData;
  formDataErrors: FormDataErrors;
  handleSelectChangeFunc: (value: React.ChangeEvent<HTMLSelectElement>) => void;
  oldData: OldFormData;
  legalModalFunc: (open: any) => void;
}

const SecondPropInfoPage: React.FC<SecondPageProps> = ({
  homeownerData,
  changeStepFunc,
  handleInputChangeFunc,
  handleTextareaChangeFunc,
  handleSelectChangeFunc,
  editFormData,
  formDataErrors,
  oldData,
  legalModalFunc,
}): JSX.Element => {
  const { mailingDetails, changeDetails, propertyDetails, isMemberOfTeam } = homeownerData;
  const { lgOwnerFirstName, lgOwnerLastName } = changeDetails;
  // const { propertySubtype } = propertyDetails;
  // console.log('propertySubtype', propertySubtype);
  let {
    SiteNumber,
    SiteStreet,
    SiteState,
    SiteCity,
    BedRooms,
    Baths,
    Lotsqft,
    MailAddress,
    MailCity,
    MailState,
    MailZip,
  } = mailingDetails;
  console.log('mailingDetails', mailingDetails);
  const { oldFullName, oldEmail, oldPhoneNumber, oldBathrooms, oldSqft, oldLotSqft, oldComments } = oldData;

  let totalBaths = Number(oldBathrooms);
  let bathroomList = Array.from(Array(21).keys()).map((x) => {
    let updatedX = x;
    return (updatedX /= 2);
  });

  let numList = Array.from(Array(11).keys()).map((x) => {
    return x;
  });
  //adding comment
  return (
    <div className={classnames(classes.propertyInfoPage)}>
      <div className={classnames(classes.propertyInfoContentContainer2, classes.secondContentContainer)}>
        <PageTracker activeNum={2} changeStepFunc={changeStepFunc} />
        <div className={classes.secondPageCol}>
          <div className={classes.secondPageTitle}>Your Property Details</div>

          {SiteNumber ? (
            <div className={classes.secondAddress}>
              <div>{SiteNumber}</div>
              <div className={classes.secondStreetName}>{lc(SiteStreet)},</div>
              <div>{lc(SiteCity)}</div>
              <div className={classes.stateText}>{lc(SiteState)}</div>
            </div>
          ) : (
            <div className={classes.secondAddress}>
              <div>{MailAddress}</div>

              <div>{lc(MailCity)}</div>
              <div>{lc(MailState)}</div>
            </div>
          )}
          <div className={classes.secondPageDetails}>
            {SiteNumber ? (
              <div>
                Below is the information {IOrWe(isMemberOfTeam)} have sourced from the county records about {SiteNumber}{' '}
                {lc(SiteStreet, true)}, {lc(SiteCity, true)}.
              </div>
            ) : (
              <div>
                Below is the information {IOrWe(isMemberOfTeam)} have sourced from the county records about{' '}
                {MailAddress}, {lc(MailCity, true)}.
              </div>
            )}
            <div>If necessary, click on any of the four circles to adjust the numbers.</div>
          </div>

          <div className={classes.propDetailsContainer}>
            <div className={classes.propDetailsBox}>
              <div className={classnames(classes.propDetailsCircle, classes.propCircle1)}>
                <input
                  className={classes.bubbleInput}
                  value={editFormData.bedrooms}
                  id="bedrooms"
                  name="bedrooms"
                  type="text"
                  required
                  onChange={(val) => {
                    handleInputChangeFunc(val);
                  }}
                />
                <div className={classes.propDetailsText}>
                  {/*    <select
                    className={classes.bubbleSelect}
                    name="bedrooms"
                    onChange={handleSelectChangeFunc}
                    value={editFormData.bedrooms}
                  >
                    {numList.map((num, index) => {
                      return (
                        <option key={num} value={num}>
                          {num}
                          {num === 10 && '+'}
                        </option>
                      );
                    })}
                  </select> */}
                </div>
              </div>

              <div className={classes.propDetailsTitle}>Bedrooms</div>
              {formDataErrors.bedroomsError && (
                <div className={classes.formError}>
                  <p>{formDataErrors.bedroomsError}</p>
                </div>
              )}
            </div>

            <div className={classes.propDetailsBox}>
              <div className={classnames(classes.propDetailsCircle, classes.propCircle2)}>
                <div className={classnames(classes.propDetailsText)}>
                  <input
                    className={classes.bubbleInput}
                    value={editFormData.bathrooms}
                    id="bathrooms"
                    name="bathrooms"
                    type="text"
                    required
                    onChange={(val) => {
                      handleInputChangeFunc(val);
                    }}
                  />
                </div>
              </div>
              <div className={classes.propDetailsTitle}>Bathrooms</div>
              {formDataErrors.bathroomsError && (
                <div className={classes.formError}>
                  <p>{formDataErrors.bathroomsError}</p>
                </div>
              )}
            </div>

            <div className={classes.propDetailsBox}>
              <div className={classnames(classes.propDetailsCircle, classes.propCircle3)}>
                <div className={classes.propDetailsText}>
                  <input
                    className={classes.bubbleInput}
                    value={editFormData.sqft}
                    id="sqft"
                    name="sqft"
                    type="text"
                    required
                    onChange={(val) => {
                      handleInputChangeFunc(val);
                    }}
                  />
                </div>
              </div>
              <div className={classes.propDetailsTitle}>Living SqFt</div>
              {formDataErrors.sqftError && (
                <div className={classes.formError}>
                  <p>{formDataErrors.sqftError}</p>
                </div>
              )}
            </div>
            {propertyDetails && propertyDetails.propertySubtype && propertyDetails.propertySubtype === 'Condominium' ? (
              <div className={classes.propDetailsBox}>
                <div className={classnames(classes.propDetailsCircle, classes.propCircle4)}>
                  <div className={classes.propDetailsText}>
                    <div>N/A</div>
                  </div>
                </div>
                <div className={classes.propDetailsTitle}>Lot Size (SqFt)</div>
                {formDataErrors.lotSqftError && (
                  <div className={classes.formError}>
                    <p>{formDataErrors.lotSqftError}</p>
                  </div>
                )}
              </div>
            ) : (
              <div className={classes.propDetailsBox}>
                <div className={classnames(classes.propDetailsCircle, classes.propCircle4)}>
                  <div className={classes.propDetailsText}>
                    <input
                      className={classes.bubbleInput}
                      value={editFormData.lotSqft}
                      id="lotSqft"
                      name="lotSqft"
                      type="text"
                      required
                      onChange={(val) => {
                        handleInputChangeFunc(val);
                      }}
                    />
                  </div>
                </div>
                <div className={classes.propDetailsTitle}>Lot Size (SqFt)</div>
                {formDataErrors.lotSqftError && (
                  <div className={classes.formError}>
                    <p>{formDataErrors.lotSqftError}</p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={classes.additionalInfo}>
            <div className={classes.additionalInfoText}>
              Using the lines below, please add any additional information that can help {MeOrUs(isMemberOfTeam)} better
              understand your property&apos;s value.
            </div>
          </div>
          <div className={classes.commentsInputContainer}>
            <textarea
              className={classes.commentsInput}
              value={editFormData.comments}
              onChange={(val) => {
                handleTextareaChangeFunc(val);
              }}
              id="comments"
              name="comments"
            />
          </div>
          <div
            className={classes.secondPageSubmitBtn}
            onClick={() => {
              changeStepFunc(3);
            }}
          >
            <div className={classes.secondPageValuationButton}>
              <div className={classes.submitValuationButtonText}>Submit Your Home Valuation Request</div>
            </div>
          </div>
          <div className={classes.privacyDisclaimerContainer}>
            <div className={classes.privacyDisclaimerText}>
              Your information is always held private, and there is no obligation
            </div>
          </div>
        </div>
      </div>
      <CoverPageInfo homeownerData={homeownerData} legalModalFunc={legalModalFunc} />
    </div>
  );
};

export default SecondPropInfoPage;
