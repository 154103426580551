import React, { useEffect } from 'react';
import { DataContext } from '../../store/Context';
import ClientLayout from '../../layouts/ClientLayout';
import { useContext, useState } from 'react';
import classes from './landing.module.css';
import { StoreData, HOData } from '../../types/hoDataTypes';
import ListGenBanner from '../../homeownerImages/listgen-banner.jpg';

const Landing: React.FC = () => {
  const storeData: StoreData = useContext(DataContext);

  let homeownerData: HOData = storeData.hoData;

  return (
    <ClientLayout>
      <div className={classes.listgenBanner}>
        <img src={ListGenBanner} alt="ListGen Banner" />
      </div>
    </ClientLayout>
  );
};

export default Landing;
