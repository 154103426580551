import classes from '../page.module.css';
import PageTracker from './PropInfoCommon/PageTracker';
import classnames from 'classnames';
import CoverPageInfo from './PropInfoCommon/CoverPageInfo';
import { HOData, HomeownerEditFormData, FormDataErrors } from '../../types/hoDataTypes';
import { useRef, useEffect } from 'react';
import { lc } from '../../util/propertyUtil.jsx';

interface ThirdPageProps {
  homeownerData: HOData;
  changeStepFunc: (step: number) => void;
  submitHomeownerInfoFunc: () => void;

  handleInputChangeFunc: (value: React.ChangeEvent<HTMLInputElement>) => void;
  editFormData: HomeownerEditFormData;
  formDataErrors: FormDataErrors;
  legalModalFunc: (open: any) => void;
}

const ThirdPropInfoPage: React.FC<ThirdPageProps> = ({
  homeownerData,
  changeStepFunc,
  handleInputChangeFunc,
  editFormData,
  formDataErrors,
  submitHomeownerInfoFunc,
  legalModalFunc,
}): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { mailingDetails, changeDetails } = homeownerData;
  const { lgOwnerFirstName, lgOwnerLastName } = changeDetails;
  let { SiteNumber, SiteStreet, SiteState, SiteCity } = mailingDetails;

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.focus();
  }, []);
  return (
    <div className={classnames(classes.propertyInfoPage, classes.fourthPage)}>
      <div className={classnames(classes.propertyInfoContentContainer2, classes.thirdContentContainer)}>
        <PageTracker activeNum={3} changeStepFunc={changeStepFunc} />
        <div className={classes.thirdPageCol}>
          <div className={classes.thirdAddress}>Thank You For Your Home Valuation Request</div>
          <div className={classes.thirdTitle}>Last Step...</div>
          <div className={classes.thirdFormContainer}>
            <form className={classes.thirdForm}>
              <div className={classes.fieldHolder}>
                <input
                  id="fullName"
                  name="fullName"
                  className={classes.thirdInput}
                  value={editFormData.fullName}
                  type="text"
                  required
                  onChange={handleInputChangeFunc}
                  ref={inputRef}
                />

                <label htmlFor="fullName" className={classes.thirdLabel}>
                  First & Last Name:
                </label>
              </div>

              <div className={classes.formError}>
                <p>{formDataErrors.fullNameError && formDataErrors.fullNameError}</p>
              </div>

              <div className={classes.fieldHolder}>
                <input
                  id="email"
                  name="email"
                  className={classes.thirdInput}
                  type="text"
                  required
                  onChange={(val) => {
                    handleInputChangeFunc(val);
                  }}
                />
                <label htmlFor="email" className={classes.thirdLabel} placeholder="email">
                  Email:
                </label>
              </div>

              <div className={classes.formError}>
                <p>{formDataErrors.emailError && formDataErrors.emailError}</p>
              </div>

              <div className={classes.fieldHolder}>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  className={classes.thirdInput}
                  type="text"
                  required
                  onChange={(value) => {
                    handleInputChangeFunc(value);
                  }}
                />
                <label htmlFor="phoneNumber" className={classes.thirdLabel}>
                  Phone Number:
                </label>
              </div>

              <div className={classes.formError}>
                <p>{formDataErrors.phoneNumberError && formDataErrors.phoneNumberError}</p>
              </div>
            </form>
          </div>
          <div className={classes.thirdSubmitButtonBorder} onClick={submitHomeownerInfoFunc}>
            <div className={classes.thirdSubmitButton}>
              <div className={classes.thirdSubmitButtonText}>Submit</div>
            </div>
          </div>
          <div className={classes.privacyDisclaimerContainer}>
            <div className={classes.privacyDisclaimerText}>
              Your information is held private. There is no obligation.
            </div>
          </div>
        </div>
      </div>
      <CoverPageInfo homeownerData={homeownerData} legalModalFunc={legalModalFunc} />
    </div>
  );
};

export default ThirdPropInfoPage;
