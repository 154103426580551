import classes from '../page.module.css';
import PageTracker from './PropInfoCommon/PageTracker';
import classnames from 'classnames';
import CoverPageInfo from './PropInfoCommon/CoverPageInfo';
import AgentInfoFooter from './PropInfoCommon/AgentInfoFooter';
import { HOData } from '../../types/hoDataTypes';
import PMACover from '../../homeownerImages/cover.png';
import { getAgentSignoff, ImOrWere, AmOrAre } from '../../util/propertyUtil.jsx';

interface FirstPageProps {
  homeownerData: HOData;
  changeStepFunc: (step: number) => void;
  legalModalFunc: (open: boolean) => void;
}

const FirstPropInfoPage: React.FC<FirstPageProps> = ({
  homeownerData,
  changeStepFunc,
  legalModalFunc,
}): JSX.Element => {
  const { mailingDetails, changeDetails, isMemberOfTeam, areaID } = homeownerData;

  let { SiteNumber, SiteStreet, SiteState, SiteCity } = mailingDetails;
  console.log('page 1 homeownerData', homeownerData);
  return (
    <div className={classes.propertyInfoPage}>
      <div className={classes.propertyInfoTopRow}>
        <div className={classnames(classes.propertyInfoContentContainerFirstPage, classes.firstPage)}>
          <PageTracker activeNum={1} changeStepFunc={changeStepFunc} />
          <div className={classes.firstPageRow}>
            <div className={classes.firstCol}>
              <div className={classes.firstHomeownerNames}>
                <div>Welcome, Neighbor</div>
              </div>

              <div className={classes.firstDescriptionContainer}>
                <p>
                  {ImOrWere(isMemberOfTeam, true)} pleased to provide you with a complimentary home valuation as a
                  starting point in understanding how your home could be priced in today’s market.
                </p>
              </div>
              <div className={classes.firstAgentName}>
                <p>— {getAgentSignoff(homeownerData)}</p>
              </div>
              <div
                className={classes.valuationButtonBorder}
                onClick={() => {
                  changeStepFunc(2);
                }}
              >
                <div className={classes.valuationButton}>
                  <div className={classes.valuationButtonText}>Request Your Home Valuation</div>
                </div>
              </div>
              <div className={classes.privacyDisclaimerContainer}>
                <div className={classes.privacyDisclaimerText}>
                  Your information is always held private, and there is no obligation
                </div>
              </div>
            </div>
            <div className={classes.firstCol}>
              <div className={classes.firstCoverContainer}>
                <img
                  className={classes.firstCoverImg}
                  alt="PMA Cover"
                  src={`https://pma-cover-thumbnails.s3.amazonaws.com/${areaID}.jpg`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CoverPageInfo homeownerData={homeownerData} legalModalFunc={legalModalFunc} />
    </div>
  );
};

export default FirstPropInfoPage;
