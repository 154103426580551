import { Dispatch } from 'react';
import { ActionTypes } from './ActionTypes';
import { DispatchAction } from './Context';
import { HOData } from '../types/hoDataTypes';

export const useActions = (dispatch: Dispatch<DispatchAction>): any => {
  return {
    setLoading(isLoading: boolean) {
      return dispatch({
        type: ActionTypes.SET_LOADING,
        payload: isLoading,
      });
    },

    updateQRValidity(isValid: boolean) {
      return dispatch({
        type: ActionTypes.SET_QR_VALIDITY,
        payload: isValid,
      });
    },

    updateHOData(data: HOData) {
      return dispatch({
        type: ActionTypes.UPDATE_HO_DATA,
        payload: data,
      });
    },
  };
};
