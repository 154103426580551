import { useReducer } from 'react';
import { DispatchAction, StoreInitialValue, storeDefaultValue } from './Context';
import { PropertyReducer } from './PropertyReducer';

export const ReducerFn = () => {
  return useReducer<(initialValue: StoreInitialValue, action: DispatchAction) => StoreInitialValue>(
    PropertyReducer,
    storeDefaultValue as StoreInitialValue
  );
};
