import { BASE_URL } from '../client/ApiConstants';

const GetOAuthTokenByKey = (key: any) => {
  console.log('key is', key);
  try {
    console.log('inside oauth');

    // let oAuthTokenData = JSON.parse(localStorage.getItem(BASE_URL.LocalStorageBase.LG_OAUTH_TOKEN) || '{}');
    // return oAuthTokenData[key] ? oAuthTokenData[key] : '';
  } catch (err) {
    return '';
  }
};

export { GetOAuthTokenByKey };
