import React, { Dispatch } from 'react';
import { ActionTypes } from './ActionTypes';
import { HOData } from '../types/hoDataTypes';

export interface StoreInitialValue {
  payload?: any;
  hoData: HOData | {};
  isLoading: boolean;
  isValidQRCode: boolean;
}

export interface DispatchAction {
  type: ActionTypes;
  payload: any;
}

export const storeDefaultValue: StoreInitialValue = {
  isLoading: false,
  isValidQRCode: false,
  hoData: {},
};

export const DataContext = React.createContext(storeDefaultValue);
export const DataContextDispatch = React.createContext<Dispatch<DispatchAction>>((x: DispatchAction) => {});
