import { HomeownerURLData } from '../types/dataTypes';
import { HOData } from '../types/hoDataTypes';
import { FETCH_HOMEOWNER_DETAILS, SUBMIT_HO_FORM, SUBMIT_MAX_HO_SERVICE } from './ApiConstants';
import CommonAPICaller from './CommonAPICaller';
import { GetOAuthTokenByKey } from '../storage';

export const fetchHomeownerDetails = (data: HomeownerURLData) => {
  return CommonAPICaller('GET', FETCH_HOMEOWNER_DETAILS, data);
};

export const submitHoForm = (data: HOData) => {
  console.log('inside submit form ho service', data);
  return CommonAPICaller('POST', SUBMIT_HO_FORM, data, {
    Authorization: `Bearer ${GetOAuthTokenByKey('access_token')}`,
  });
};

export const submitMaxHOSteps = (data: { mailerProID: string; hoStepsCompleted: number }) => {
  return CommonAPICaller('GET', SUBMIT_MAX_HO_SERVICE, data);
};
