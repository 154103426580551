import React, { useEffect } from 'react';
import { DataContext, DataContextDispatch } from '../store/Context';
import ClientLayout from '../layouts/ClientLayout';
import { useContext, useState } from 'react';
import { useActions } from '../store/Actions';

import classes from './page.module.css';
import { StoreData, HOData } from '../types/hoDataTypes';
import FirstPropInfoPage from './PropInfoPages/FirstPropInfoPage';
import SecondPropInfoPage from './PropInfoPages/SecondPropInfoPage';
import ThirdPropInfoPage from './PropInfoPages/ThirdPropInfoPage';
import FourthPropInfoPage from './PropInfoPages/FourthPropInfoPage';
import Legal from './legal';
import { submitHoForm, submitMaxHOSteps } from '../client/HOService';
import { useHistory } from 'react-router-dom';

const PropertyInfoPage: React.FC = () => {
  const history = useHistory();
  const storeData: StoreData = useContext(DataContext);
  const dispatch = useContext(DataContextDispatch);
  const actions = useActions(dispatch);
  let homeownerData: HOData = storeData.hoData;

  const [step, setStep] = useState<number>(1);
  const [maxStep, setMaxStep] = useState<number>(1);
  const [dataType, setDataType] = useState<string>('');
  const [legalModalOpen, setLegalModalOpen] = useState(false);
  const { mailingDetails, changeDetails, propertyDetails, mailerProID } = homeownerData;

  const { lgOwnerFirstName, lgOwnerLastName } = changeDetails;
  let { MailAddress, MailCity, MailState } = mailingDetails;
  const [formDataErrors, setFormDataErrors] = React.useState<{
    fullNameError: string;
    emailError: string;
    phoneNumberError: string;
    bedroomsError: string;
    bathroomsError: string;
    sqftError: string;
    lotSqftError: string;
    commentsError: string;
  }>({
    fullNameError: '',
    emailError: '',
    phoneNumberError: '',
    bedroomsError: '',
    bathroomsError: '',
    sqftError: '',
    lotSqftError: '',
    commentsError: '',
  });

  const addCommas = (value: string) => {
    let tempVal = '';
    if (value) {
      let parsed = Math.floor(+value).toString();

      let removeChar = parsed.replace(/[^0-9\.]/g, '');
      var removeDot = removeChar.replace(/\./g, ''); // This is to remove "DOT"
      let addCommasToText = removeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      tempVal = addCommasToText;
    }
    return tempVal;
  };
  const removeCommas = (value: string) => {
    console.log('value is', value);
    let replaceCommas = value.replace(/[^0-9\.]/g, '');
    console.log('after replacing', replaceCommas);
    return replaceCommas;
  };
  const toSqft = (num: string) => {
    let toNum = Number(num);
    let result = String(Math.floor(toNum * 43560));
    console.log('result', result);
    return result;
  };

  const [editFormData, setFormData] = React.useState<{
    fullName: string;
    email: string;
    phoneNumber: string;
    bedrooms: number;
    bathrooms: number;
    sqft: string;
    lotSqft: string;
    comments: string;
  }>({
    fullName: '',
    email: '',
    phoneNumber: '',
    bedrooms: 0,
    bathrooms: 0,
    sqft: '0',
    lotSqft: '0',
    comments: '',
  });

  const [oldData, setOldData] = React.useState<{
    oldFullName: string;
    oldEmail: string;
    oldPhoneNumber: string;
    oldBedrooms: number;
    oldBathrooms: number;
    oldSqft: string;
    oldLotSqft: string;
    oldComments: string;
  }>({
    oldFullName: '',
    oldEmail: '',
    oldPhoneNumber: '',
    oldBedrooms: 0,
    oldBathrooms: 0,
    oldSqft: '0',
    oldLotSqft: '0',
    oldComments: '',
  });

  useEffect(() => {
    let homeDetails = {
      fullName: '',
      email: '',
      phoneNumber: '',
      bedrooms: 0,
      bathrooms: 0,
      sqft: '0',
      lotSqft: '0',
      comments: '',
    };
    let oldHomeDetails = {
      oldFullName: '',
      oldEmail: '',
      oldPhoneNumber: '',
      oldBedrooms: 0,
      oldBathrooms: 0,
      oldSqft: '0',
      oldLotSqft: '0',
      oldComments: '',
    };
    if (propertyDetails && Object.keys(propertyDetails).length >= 1) {
      console.log('inside func');
      const { beds, fullBaths, halfBaths, squareFeet, lotSize } = propertyDetails;
      console.log('propertyDetails', propertyDetails);
      let { Lotsqft } = mailingDetails;
      let lotToUse;
      if (lotSize && Number(lotSize) < 1) {
        console.log('***less than 1');
        lotToUse = toSqft(lotSize);
      } else if (lotSize) {
        console.log('***more than 1');
        lotToUse = lotSize;
      } else {
        console.log('**use from mailing');
        lotToUse = Lotsqft;
      }

      //homeDetails.fullName = `${lgOwnerFirstName} ${lgOwnerLastName}`;
      homeDetails.email = '';
      homeDetails.phoneNumber = '';
      homeDetails.bedrooms = beds === '-' || beds === null ? 0 : Number(beds);
      homeDetails.bathrooms = fullBaths === '-' || fullBaths === null ? 0 : Number(fullBaths) + Number(halfBaths);
      homeDetails.sqft = squareFeet === '-' || squareFeet === null ? '0' : addCommas(squareFeet);
      homeDetails.lotSqft = lotToUse === '-' || lotToUse === null ? '0' : addCommas(lotToUse);
      homeDetails.comments = '';

      oldHomeDetails.oldFullName = `${lgOwnerFirstName} ${lgOwnerLastName}`;
      oldHomeDetails.oldEmail = '';
      oldHomeDetails.oldPhoneNumber = '';
      oldHomeDetails.oldBedrooms = beds === '-' || beds === null ? 0 : Number(beds);
      oldHomeDetails.oldBathrooms = fullBaths === '-' || fullBaths === null ? 0 : Number(fullBaths) + Number(halfBaths);
      oldHomeDetails.oldSqft = lotToUse === '-' || lotToUse === null ? '0' : addCommas(squareFeet);
      oldHomeDetails.oldLotSqft = lotToUse === '-' || lotToUse === null ? '0' : addCommas(lotToUse);
      oldHomeDetails.oldComments = '';
    } else {
      let { Lotsqft, BedRooms, Baths, Totalsf } = mailingDetails;
      // homeDetails.fullName = `${lgOwnerFirstName} ${lgOwnerLastName}`;
      homeDetails.email = '';
      homeDetails.phoneNumber = '';
      homeDetails.bedrooms = BedRooms === '-' || BedRooms === null ? 0 : Number(BedRooms);
      homeDetails.bathrooms = Baths === '-' || Baths === null ? 0 : Number(Baths);
      homeDetails.sqft = Totalsf === '-' || Totalsf === null ? '0' : addCommas(Totalsf);
      homeDetails.lotSqft = Lotsqft === '-' || Lotsqft === null ? '0' : addCommas(Lotsqft);
      homeDetails.comments = '';

      oldHomeDetails.oldFullName = `${lgOwnerFirstName} ${lgOwnerLastName}`;
      oldHomeDetails.oldEmail = '';
      oldHomeDetails.oldPhoneNumber = '';
      oldHomeDetails.oldBedrooms = Number(BedRooms);
      oldHomeDetails.oldBathrooms = Number(Baths);
      oldHomeDetails.oldSqft = addCommas(Totalsf);
      oldHomeDetails.oldLotSqft = addCommas(Lotsqft);
      oldHomeDetails.oldComments = '';
    }

    setFormData(homeDetails);

    setOldData(oldHomeDetails);
    //set HO STeps here
  }, []);

  const clearErrors = () => {
    const emptiedErrors = {
      fullNameError: '',
      emailError: '',
      phoneNumberError: '',
      bedroomsError: '',
      bathroomsError: '',
      sqftError: '',
      lotSqftError: '',
      commentsError: '',
    };
    console.log('emptiedErrors is', emptiedErrors);
    setFormDataErrors(emptiedErrors);
  };
  const validateForm = () => {
    console.log('**inside validateForm');
    //clearErrors();
    const emptiedErrors = {
      fullNameError: '',
      emailError: '',
      phoneNumberError: '',
      bedroomsError: '',
      bathroomsError: '',
      sqftError: '',
      lotSqftError: '',
      commentsError: '',
    };
    console.log('emptiedErrors is', emptiedErrors);
    setFormDataErrors(emptiedErrors);
    console.log('***after clearing errors', formDataErrors);

    const {
      fullNameError,
      emailError,
      phoneNumberError,
      bedroomsError,
      bathroomsError,
      sqftError,
      lotSqftError,
      commentsError,
    } = formDataErrors;
    const { fullName, email, phoneNumber, bedrooms, bathrooms, sqft, lotSqft, comments } = editFormData;

    let formErrors = Object.assign({}, emptiedErrors);

    console.log('formerrors', formErrors);
    let hasError = false;
    let emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let numberRegex =
      /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

    if (step === 2) {
      if (isNaN(+bedrooms) || +bedrooms == 0) {
        hasError = true;
        formErrors.bedroomsError = 'Sorry, Number of bedrooms is not valid';
      }

      if (isNaN(+bathrooms) || +bathrooms == 0) {
        hasError = true;
        formErrors.bathroomsError = 'Sorry, Number of bathrooms is not valid';
      }

      // if (isNaN(+sqft) || +sqft === 0) {
      //   hasError = true;
      //   formErrors.sqftError = 'Sorry, SQFT is not valid';
      // } else if ((+sqft > 0 && +sqft % 1 != 0) || +sqft.toString().includes('.')) {
      //   hasError = true;
      //   formErrors.sqftError = 'Sorry, Square Feet cannot contain a decimal';
      // }

      // if (isNaN(+lotSqft) || +lotSqft === 0) {
      //   hasError = true;
      //   formErrors.lotSqftError = 'Sorry, Lot Size is not valid';
      // } else if ((+lotSqft && +lotSqft % 1 != 0) || +lotSqft.toString().includes('.')) {
      //   hasError = true;
      //   formErrors.lotSqftError = 'Sorry, Lot Size cannot contain a decimal';
      // }
    } else if (step === 3) {
      if (fullName.length < 2) {
        hasError = true;
        formErrors.fullNameError = 'Sorry, Name needs to be at least 2 characters';
      }
      if (emailRegex.test(email) === false) {
        hasError = true;
        formErrors.emailError = 'Sorry, Not a valid e-mail';
      }
      if (numberRegex.test(phoneNumber) === false) {
        hasError = true;
        formErrors.phoneNumberError = 'Sorry, Not a valid phone number';
        // formErrors.rMobilePhone =
      }
    }

    if (hasError) {
      setFormDataErrors(formErrors);
    }
    return hasError;

    // setFormDataErrors(formErrors);
  };
  const changeLegalModal = (status: boolean) => {
    console.log('inside change legal modal');
    setLegalModalOpen(status);
    return;
  };
  const changeStep = (newStep: number) => {
    console.log('**edit form data', editFormData);
    if (step === 3 && newStep === 1) return;
    if (newStep === 3) {
      // /admin/setHoValuationSiteStepVisitedByHO
      submitMaxHOSteps({ mailerProID: mailerProID, hoStepsCompleted: 2 });
    }

    if (newStep === 3 || newStep === 4) {
      let errors = validateForm();

      if (errors) {
        console.log('errors');
      } else {
        setStep(newStep);
        setMaxStep(Math.max(step, newStep));
      }
    } else {
      setStep(newStep);
      setMaxStep(Math.max(step, newStep));
    }
  };
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const target = e.target;

    const value = +target.value;
    const name = target.name;
    setFormData({
      ...editFormData,
      [e.target.name]: value,
    });
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // function updateTextView(_obj){
    //   var num = getNumber(_obj.val());
    //   if(num==0){
    //     _obj.val('');
    //   }else{
    //     _obj.val(num.toLocaleString());
    //   }
    // }
    // function getNumber(_str){
    //   var arr = _str.split('');
    //   var out = new Array();
    //   for(var cnt=0;cnt<arr.length;cnt++){
    //     if(isNaN(arr[cnt])==false){
    //       out.push(arr[cnt]);
    //     }
    //   }
    //   return Number(out.join(''));
    // }
    // $(document).ready(function(){
    //   $('input[type=text]').on('keyup',function(){
    //     updateTextView($(this));
    //   });
    // });

    const target = e.target;
    let value = target.value;
    const name = target.name;
    setFormData({
      ...editFormData,
      [e.target.name]: value,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;

    let value = target.value;
    const name = target.name;
    console.log('input change', value);

    if (target.name === 'lotSqft' || target.name === 'sqft') {
      let removed = removeCommas(value);
      value = addCommas(removed);
    }

    setFormData({
      ...editFormData,

      [e.target.name]: value,
    });
  };

  const submitHomeownerInfo = () => {
    // e.preventDefault();
    // e.preventDefault();

    let errors = validateForm();

    // if (!errors) {
    //   this.setState({
    //     submitted: true,
    //   });
    // }
    if (errors) {
      console.log('has errors');
      return;
    }

    const { fullName, email, phoneNumber, bedrooms, bathrooms, sqft, lotSqft, comments } = editFormData;

    const { oldFullName, oldEmail, oldPhoneNumber, oldBedrooms, oldBathrooms, oldSqft, oldLotSqft, oldComments } =
      oldData;

    let bathSum = oldBathrooms;
    let sameBeds = bedrooms == Number(oldBedrooms);

    let isHomeownerModified =
      bedrooms !== Number(oldBedrooms) || bathrooms !== bathSum || sqft !== oldSqft || lotSqft !== oldLotSqft;
    const hasValue = (val: any) => {
      if (val === '' || val === '-') {
        return '0';
      } else {
        return val;
      }
    };
    let hoSubmittedDetails = {
      submittedFullName: fullName,
      submittedBedrooms: hasValue(String(bedrooms)),
      submittedBathrooms: hasValue(String(bathrooms)),
      submittedLivingSqft: hasValue(removeCommas(sqft)),
      submittedLotSize: hasValue(removeCommas(lotSqft)),
      submittedPhoneNo: phoneNumber,
      submittedEMailID: email,
      submittedComments: comments,
      isBedroomsUpdated: bedrooms !== Number(oldBedrooms),
      isBathroomsUpdated: bathrooms !== oldBathrooms,
      isLivingSqftUpdated: sqft !== oldSqft,
      isLotSizeUpdated: lotSqft !== oldLotSqft,
    };
    console.log('hoSubmittedDetails', hoSubmittedDetails);

    let newObj = { ...homeownerData, hoSubmittedDetails: hoSubmittedDetails, isHomeownerModified: isHomeownerModified };

    actions.setLoading(true);
    newObj &&
      submitHoForm(newObj)
        .then((apiResponse: any) => {
          // actions.updateQRValidity(true);
          console.log('apiResponse', apiResponse);

          // actions.updateHOData(apiResponse.data as HOData);
        })
        .catch((error: string) => {
          console.error('Error validating qr code:', error);

          history.push('/invalid');
        })
        .finally(() => {
          actions.setLoading(false);
        });
    actions.setLoading(false);
    changeStep(4);
  };
  // console.log('homeownerData', homeownerData);
  // console.log('propertyDetails', propertyDetails);
  // console.log('mailingDetails', mailingDetails);
  console.log('homeownerData', homeownerData);
  let BethPowersObj = Object.assign({}, homeownerData);
  console.log('BethPowersObj', BethPowersObj);
  let isBethPowers = homeownerData.listgenUserID === '106463';
  if (isBethPowers) {
    BethPowersObj.listgenUserFirstName = 'Deb';
    BethPowersObj.listgenUserLastName = 'Kockos';
    BethPowersObj.mobilePhoneNo = '4153094176';
    BethPowersObj.agentDesignation = 'Realtor';
    BethPowersObj.agentLicenseNumber = '01864875';

    BethPowersObj.teamMembers[0].firstName = 'Deb';
    BethPowersObj.teamMembers[0].lastName = 'Kockos';
    BethPowersObj.teamMembers[0].phone = '4153094176';
    BethPowersObj.teamMembers[0].agentDesignation = 'Realtor';
    BethPowersObj.teamMembers[0].agentLicenseNumber = '01864875';

    BethPowersObj.teamMembers[1].agentDesignation = 'Realtor';
    BethPowersObj.teamMembers[1].agentLicenseNumber = '02176834';
    BethPowersObj.teamMembers[1].firstName = 'Beth';
    BethPowersObj.teamMembers[1].lastName = 'Powers';
    BethPowersObj.teamMembers[1].phone = '6504006580';
  }

  return (
    <ClientLayout>
      <div className={classes.home}>
        {legalModalOpen ? (
          <Legal legalModalFunc={changeLegalModal} />
        ) : step == 1 ? (
          <FirstPropInfoPage
            homeownerData={isBethPowers ? BethPowersObj : homeownerData}
            changeStepFunc={changeStep}
            legalModalFunc={changeLegalModal}
          />
        ) : step === 2 ? (
          <SecondPropInfoPage
            homeownerData={isBethPowers ? BethPowersObj : homeownerData}
            changeStepFunc={changeStep}
            editFormData={editFormData}
            oldData={oldData}
            formDataErrors={formDataErrors}
            handleInputChangeFunc={handleInputChange}
            handleTextareaChangeFunc={handleTextareaChange}
            handleSelectChangeFunc={handleSelectChange}
            legalModalFunc={changeLegalModal}
          />
        ) : step === 3 ? (
          <ThirdPropInfoPage
            homeownerData={isBethPowers ? BethPowersObj : homeownerData}
            changeStepFunc={changeStep}
            formDataErrors={formDataErrors}
            handleInputChangeFunc={handleInputChange}
            editFormData={editFormData}
            submitHomeownerInfoFunc={submitHomeownerInfo}
            legalModalFunc={changeLegalModal}
          />
        ) : step === 4 ? (
          <FourthPropInfoPage
            homeownerData={isBethPowers ? BethPowersObj : homeownerData}
            legalModalFunc={changeLegalModal}
          />
        ) : (
          <div>ERROR</div>
        )}
      </div>
    </ClientLayout>
  );
};

export default PropertyInfoPage;
