// @ts-nocheck
import React from 'react';
import './globals/globals.css';
import './App.css';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import { DataContext, DataContextDispatch } from './store/Context';
import { ReducerFn } from './store/Reducer';

const App: React.FC = () => {
  const browserHistory = createBrowserHistory({});
  const [storeData, dispatch] = ReducerFn();

  return (
    <div>
      <DataContext.Provider value={storeData}>
        <DataContextDispatch.Provider value={dispatch}>
          <Router history={browserHistory as any}>
            <Routes />
          </Router>
        </DataContextDispatch.Provider>
      </DataContext.Provider>
    </div>
  );
};

export default App;
