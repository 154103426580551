import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import InvalidPage from './pages/invalid';
import PropertyInfo from './pages/info';
import PropertyInfoPage from './pages';
import Landing from './pages/landing';
import Legal from './pages/legal';

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route path="/:slug" component={PropertyInfo} />
      <Route path="/legal" component={Legal} />
      <Route path="/invalid" component={InvalidPage} />

      <Redirect to="/invalid" />
    </Switch>
  );
};

export default Routes;
