import { ActionTypes } from './ActionTypes';
import { DispatchAction, StoreInitialValue } from './Context';

export function PropertyReducer(initialValue: StoreInitialValue, action: DispatchAction): StoreInitialValue {
  switch (action.type) {
    case ActionTypes.SET_LOADING: {
      return { ...initialValue, ...{ isLoading: action.payload } };
    }

    case ActionTypes.SET_QR_VALIDITY: {
      return { ...initialValue, ...{ isValidQRCode: action.payload } };
    }

    case ActionTypes.UPDATE_HO_DATA: {
      return { ...initialValue, ...{ hoData: action.payload } };
    }

    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
