import { APIError, APIResponse, HTTPMethod } from '../types/dataTypes';
import { BASE_URL } from './ApiConstants';

const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    return BASE_URL;
  }
  return '';
};

const getURLParams = (data: { [key: string]: any }): string => {
  const params = new URLSearchParams();
  for (let key in data) {
    params.append(key, data[key]);
  }
  return params.toString();
};

async function CommonAPICaller<T>(
  method: HTTPMethod,
  url: string,
  data?: any,
  headers?: HeadersInit
): Promise<APIResponse<T>> {
  try {
    const requestOptions: RequestInit = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    };

    let requestUrl = getBaseUrl() + url;

    // For GET requests, append data as URL parameters
    if (method === 'GET' && data) {
      const queryString = getURLParams(data);
      requestUrl = `${requestUrl}?${queryString}`;
    }

    if (method !== 'GET' && data) {
      requestOptions.body = JSON.stringify(data);
    }

    const response = await fetch(requestUrl, requestOptions);

    if (!response.ok) {
      const error: APIError = {
        status: response.status,
        message: response.statusText,
      };
      throw error;
    }

    const responseData: T = await response.json();
    const apiResponse: APIResponse<T> = {
      status: response.status,
      data: responseData,
    };

    return apiResponse;
  } catch (error) {
    console.log('error is', error);
    throw error;
  }
}

export default CommonAPICaller;
