import React from 'react';
import { PropertyInfo as PropertyInfoComponent } from '../../components/PropertyInfo/PropertyInfo';
import PropertyInfoWrapper from '../../helpers/PropertyInfoWrapper/PropertyInfoWrapper';
import ClientLayout from '../../layouts/ClientLayout';
import { RouteSlugType } from '../../types/dataTypes';
import { useParams } from 'react-router-dom';

const PropertyInfo: React.FC<RouteSlugType> = (props: any) => {
  const { slug } = useParams() as any;
  return (
    <PropertyInfoWrapper slugParams={{ slug: slug as string }}>
      <ClientLayout>
        <PropertyInfoComponent />
      </ClientLayout>
    </PropertyInfoWrapper>
  );
};
export default PropertyInfo;
