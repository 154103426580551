export const AmOrAre = (isMemberOfTeam) => {
  if (isMemberOfTeam) {
    return 'are';
  } else {
    return 'am';
  }
};
export const MeOrUs = (isMemberOfTeam) => {
  if (isMemberOfTeam) {
    return 'us';
  } else {
    return 'me';
  }
};

export const IOrWe = (isMemberOfTeam, caps) => {
  if (isMemberOfTeam) {
    if (caps) return 'We';
    return 'we';
  } else {
    return 'I';
  }
};
export const ImOrWere = (isMemberOfTeam, caps) => {
  if (isMemberOfTeam) {
    if (caps) return "We're";
    return "we're";
  } else {
    return "I'm";
  }
};
export const isUhrig = (listgenUserID) => {
  return listgenUserID === '100568';
};
export const isScarTeam = (listgenUserID) => {
  return listgenUserID === '100588';
};

export const getNoteGreetingTextV4 = ({ lgOwnerFirstName, lgSecondaryOwnerFirstName }, symbol) => {
  lgOwnerFirstName = lgOwnerFirstName && lgOwnerFirstName !== '-' ? lgOwnerFirstName : ''; //.split(' ')
  lgSecondaryOwnerFirstName =
    lgSecondaryOwnerFirstName && lgSecondaryOwnerFirstName !== '-' ? lgSecondaryOwnerFirstName : ''; //.split(" ")
  return `${
    lgOwnerFirstName
      ? `${lgOwnerFirstName}${
          lgSecondaryOwnerFirstName ? `${symbol ? ' ' + symbol + ' ' : ''}${lgSecondaryOwnerFirstName}` : ``
        }`
      : ``
  }`;
  //return `${lgOwnerFirstName[0] ? `${lgOwnerFirstName[0]}${lgSecondaryOwnerFirstName[0] ? `${symbol ? " " + symbol + " " : ""}${lgSecondaryOwnerFirstName[0]}`  : ``}` : ``}`;
};

export const lc = (word, capitalize) => {
  if (!word) {
    return '';
  } else if (capitalize) {
    const words = word.toLowerCase().split(' ');

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(' ');
  } else {
    return word.toLowerCase();
  }
};
const removeInitials = (name) => {
  if (name.includes(' ')) {
    let split = name.split(' ');
    let lastEl = split[split.length - 1];
    if (lastEl.length <= 2) {
      return split[0];
    } else {
      return split.join(' ');
    }
  } else {
    return name;
  }
};
export const agentFirstNames = (firstName, teamMembers) => {
  if (!teamMembers) return firstName;
  if (teamMembers.length === 2) {
    return `${removeInitials(teamMembers[0].firstName)} and
    ${removeInitials(teamMembers[1].firstName)}`;
  }
  if (teamMembers.length === 3) {
    return `${removeInitials(teamMembers[0].firstName)},
    ${removeInitials(teamMembers[1].firstName)}, and ${removeInitials(teamMembers[2].firstName)}`;
  }
  if (teamMembers.length === 4) {
    return `${teamMembers[0].firstName}, ${teamMembers[1].firstName}, ${teamMembers[2].firstName}, and ${teamMembers[3].firstName}`;
  }
};
export const getAgentSignoff = (agentData) => {
  const { isMemberOfTeam, teamMembers, listgenUserID, listgenUserFirstName, teamName } = agentData;
  let agentSignoff = '';
  if (isMemberOfTeam && teamMembers.length >= 2) {
    if (listgenUserID === '100329') {
      agentSignoff = 'Kit & Sue';
    } else if (isScarTeam(listgenUserID)) {
      agentSignoff = 'Team Scarborough';
    } else if (listgenUserID === '100190') {
      agentSignoff = 'Kathleen and Paul';
    } else {
      agentSignoff = agentFirstNames(listgenUserFirstName, teamMembers);
    }
  } else if (isUhrig(listgenUserID)) {
    agentSignoff = 'Bryan';
  } else if (listgenUserID === '100329') {
    agentSignoff = 'Kit & Sue';
  } else {
    agentSignoff = listgenUserFirstName;
  }
  return agentSignoff;
};

// export const propertyUtil = {
//   getNoteGreetingTextV4,
//   lc,
// };
