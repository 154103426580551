'use client';

import React from 'react';
import classes from './page.module.css';

const InvalidPage: React.FC = () => {
  return (
    <div className={classes.errorContainer}>
      <h1>Invalid Property URL</h1>
      <p>Oops! Looks like you have entered a invalid property code. Please check your property code.</p>
    </div>
  );
};

export default InvalidPage;
